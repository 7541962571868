.UserTypeSelect_Tab_3-WAU {
  width: 50%;
}

.common_Row_mq_ng {
  margin-bottom: 32px;
}
.common_Row_mq_ng:last-child {
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .common_Row_mq_ng {
    margin-bottom: 24px;
  }
  .common_Row_mq_ng:last-child {
    margin-bottom: 16px;
  }
}
.common_RowTopMargin_3Yu5c {
  margin-top: 16px;
}
.common_RowText_2z7Lw {
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 768px) {
  .common_RowText_2z7Lw {
    font-size: 14px;
    line-height: 20px;
  }
}
.common_RowPassword_3OrRp {
  margin-bottom: 8px;
}
.common_RowTitle_3YpeR {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 768px) {
  .common_RowTitle_3YpeR {
    font-size: 20px;
    line-height: 28px;
  }
}
.common_RowForgot_1g9nK {
  display: flex;
  justify-content: flex-end;
}
.common_Forgot_3oCsb {
  color: #0048FF !important;
}
.common_Forgot_3oCsb:hover {
  color: #0032B3 !important;
}
.common_Forgot_3oCsb:active {
  box-shadow: none !important;
}
.common_RowError_16cH2 {
  text-align: center;
  color: #f04240;
}
.common_Buttons_1sjHn {
  display: flex;
  justify-content: center;
}
.common_Button_17HA4 {
  font-weight: bold;
}
.common_Button_17HA4:active {
  box-shadow: none !important;
}

.SignUp_Row_3yn-4 {
  margin-bottom: 32px;
}
.SignUp_Row_3yn-4:last-child {
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .SignUp_Row_3yn-4 {
    margin-bottom: 24px;
  }
  .SignUp_Row_3yn-4:last-child {
    margin-bottom: 16px;
  }
}
.SignUp_RowTopMargin_28E5p {
  margin-top: 16px;
}
.SignUp_RowText_2yrl7 {
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 768px) {
  .SignUp_RowText_2yrl7 {
    font-size: 14px;
    line-height: 20px;
  }
}
.SignUp_RowPassword_kKFpD {
  margin-bottom: 8px;
}
.SignUp_RowTitle_1Waxv {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 768px) {
  .SignUp_RowTitle_1Waxv {
    font-size: 20px;
    line-height: 28px;
  }
}
.SignUp_RowForgot_fvb2p {
  display: flex;
  justify-content: flex-end;
}
.SignUp_Forgot_3koqA {
  color: #0048FF !important;
}
.SignUp_Forgot_3koqA:hover {
  color: #0032B3 !important;
}
.SignUp_Forgot_3koqA:active {
  box-shadow: none !important;
}
.SignUp_RowError_1DRtv {
  text-align: center;
  color: #f04240;
}
.SignUp_Buttons_3a5m4 {
  display: flex;
  justify-content: center;
}
.SignUp_Button_e_W62 {
  font-weight: bold;
}
.SignUp_Button_e_W62:active {
  box-shadow: none !important;
}
.SignUp_RowWithText_2rRnC {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .SignUp_RowWithText_2rRnC {
    margin-bottom: 16px;
  }
}

.ForgotPasswordCompany_Row_2ubkg {
  margin-bottom: 32px;
}
.ForgotPasswordCompany_Row_2ubkg:last-child {
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .ForgotPasswordCompany_Row_2ubkg {
    margin-bottom: 24px;
  }
  .ForgotPasswordCompany_Row_2ubkg:last-child {
    margin-bottom: 16px;
  }
}
.ForgotPasswordCompany_RowTopMargin_1Y-2m {
  margin-top: 16px;
}
.ForgotPasswordCompany_RowText_21-GI {
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 768px) {
  .ForgotPasswordCompany_RowText_21-GI {
    font-size: 14px;
    line-height: 20px;
  }
}
.ForgotPasswordCompany_RowPassword_37m75 {
  margin-bottom: 8px;
}
.ForgotPasswordCompany_RowTitle_3uK4u {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 768px) {
  .ForgotPasswordCompany_RowTitle_3uK4u {
    font-size: 20px;
    line-height: 28px;
  }
}
.ForgotPasswordCompany_RowForgot_1RSik {
  display: flex;
  justify-content: flex-end;
}
.ForgotPasswordCompany_Forgot_2ezlc {
  color: #0048FF !important;
}
.ForgotPasswordCompany_Forgot_2ezlc:hover {
  color: #0032B3 !important;
}
.ForgotPasswordCompany_Forgot_2ezlc:active {
  box-shadow: none !important;
}
.ForgotPasswordCompany_RowError_1ExK6 {
  text-align: center;
  color: #f04240;
}
.ForgotPasswordCompany_Buttons_1QsRs {
  display: flex;
  justify-content: center;
}
.ForgotPasswordCompany_Button_-dINS {
  font-weight: bold;
}
.ForgotPasswordCompany_Button_-dINS:active {
  box-shadow: none !important;
}
.ForgotPasswordCompany_RowTitleWithText_3T1oW {
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .ForgotPasswordCompany_RowTitleWithText_3T1oW {
    margin-bottom: 8px;
  }
}

